<template>
  <div>
    <div class="header-container">
      <v-breadcrumbs>
        <v-breadcrumbs-item class="breadcrumbs-hover-default">
          <i class="fal fa-users" style="margin: 0 10px 0 0"></i>
          <span @click="main()">{{ $t("user.title") }}</span>
        </v-breadcrumbs-item>
        <v-breadcrumbs-item class="breadcrumbs-hover" v-if="$route.name==='companyUser.create'">
          <span v-show="true">/</span>
          <span>{{ $t("user.create") }}</span>
        </v-breadcrumbs-item>

        <v-breadcrumbs-item class="breadcrumbs-hover" v-if="$route.name==='companyUser.edit'">
          <span v-show="true">/</span>
          <span>Edit User</span>
        </v-breadcrumbs-item>
      </v-breadcrumbs>
    </div>
    <div class="page-content">
      <div class="content">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {}
  },

  methods: {
    main() {
      this.$router.push({
        name: "companyUser.index"
      }).catch(() => {

      })
    },

  }
}
</script>

<style scoped lang="scss">
.content ul {
  list-style: disc outside;
  margin-left: .5em !important;
  margin-top: 1em;
}
</style>
